@import '../style/variables';

.login {
  width: 450px;
  max-width: 100%;
  position: absolute !important;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid $primary;
}
