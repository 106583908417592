@import '../../style/variables';

.problem-detail {
  pre {
    border: 1px solid $secondary;
    border-radius: 5px;
    padding: 0.75rem;
    max-width: 100%;
    white-space: pre-wrap;
    word-wrap: normal;
  }
}
