.hackathon {
  > div {
    height: 50vh;
    overflow-y: scroll;
  }

  > div:last-of-type {
    overflow-y: hidden;
  }
}

@media screen and (max-width: 575px) {
  .col-0 {
    display: none;
  }
}

.code-tabs {
  .code-editor-tab {
    width: 100%;
    transition: width 0.3s ease-in-out;
  }

  .solve-response-tab {
    width: 0;
    transition: width 0.3s 0.1s ease-in-out, opacity 0.1s linear;
    opacity: 0;
  }

  &.show-resolve-tab {
    .code-editor-tab {
      width: 75% !important;
      transition: width 0.3s ease-in-out;
    }

    .solve-response-tab {
      width: 25% !important;
      transition: width 0.3s ease-in-out, opacity 0.1s 0.3s linear;
      opacity: 1;
    }
  }
}
