.app {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
}

.text-right {
  text-align: right;
}

.cursor.pointer {
  cursor: pointer;
}
