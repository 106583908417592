.code-editor-root {
  position: relative;
  height: 99%;

  .code-editor-wrapper {
    width: 100%;
    height: 385px;
    position: relative;
    border: 1px solid #bdbdbd;

    #codeflask-top-line, #codeflask-top-line .codeflask {
      height: 40px !important;
    }

    #codeflask-container {
      .codeflask {
        top: 40px;
        height: 300px;
      }
    }

    #codeflask-bottom-line {

      &, .codeflask {
        height: 40px !important;
      }

      .codeflask {
        top: 340px;
      }
    }
  }

  .lock-overlay {
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.80);
    border: 1px solid #bdbdbd;

    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      font-size: 175%;
      text-align: center;
    }
  }

  p.alert {
    display: inline-block;
    margin: 0 0 0 15px;
    padding: 5px;
  }
}
