.challenge-detail {
  h3, h4 {
    border-bottom: 1px solid black;
  }

  .examples {
    background-color: rgb(239, 239, 239);
    padding: 0.5rem;
    margin-bottom: 0.75rem;

    h4 {
      font-size: 100%;
    }

    ul {
      list-style: square;

      li {
        margin-bottom: 0.25rem;

        pre {
          display: inline;
          font-weight: bold;
        }
      }
    }

  }

  p.description {
    font-style: italic;
  }

  .confirmation-box {
    max-width: 90%;
    margin-right: 0.25rem;
    padding: 5px;
    display: inline-block;
  }
}
