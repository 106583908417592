@import '../style/variables';

.choose-hackathon {
  width: 450px;
  max-width: 100%;
  position: absolute !important;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid $primary;

  .btn-sm {
    width: 75px;
  }
}
